<template>
  <v-main>
    <router-view />
  </v-main>
</template>

<script>
export default {};
</script>

<style></style>
